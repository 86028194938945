const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/cv/profile',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    firebase: {
        apiKey: 'AIzaSyC8jfUXSoBdMoXkF79eaAdOD4w93vosFpw',
        authDomain: 'fedr8-ddc0e.firebaseapp.com',
        projectId: 'fedr8-ddc0e',
        storageBucket: 'fedr8-ddc0e.appspot.com',
        messagingSenderId: '907626774092',
        appId: '1:907626774092:web:1c7e9a896ed93399ab3ee9',
        measurementId: 'G-XBSBGYFFS4'
    }
};

export default config;
