import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

// project imports
import config from '../config';
import * as api from '../api';

// firebase initialize
initializeApp(config.firebase);

// const
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const accountReducer = (state, action) => {
    switch (action.type) {
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [cv, setCv] = useState({});
    const auth = getAuth();

    const fetchCv = async (user) => {
        try {
            const token = await user.getIdToken();

            if (!token) {
                dispatch({
                    type: LOGOUT
                });
            } else {
                try {
                    setCv({});

                    const { data } = await api.fetchCV(token, 2);

                    if (data) {
                        setCv(data[0]);
                    }

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName
                            }
                        }
                    });
                } catch (error) {
                    console.log(error);
                    dispatch({
                        type: LOGOUT
                    });
                }
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: LOGOUT
            });
        }
    };

    useEffect(
        () =>
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    fetchCv(user);
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

    const logout = () => signOut(auth);

    const getToken = () => {
        return auth.currentUser.accessToken;
    };

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseEmailPasswordSignIn,
                login: () => {},
                logout,
                getToken,
                cv
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
