import config from "./config";

export const gridSpacing = 3;

export const customization = {
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  outlinedFilled: config.outlinedFilled,
  navType: config.theme,
  presetColor: config.presetColor,
  locale: config.i18n,
  rtlLayout: config.rtlLayout,
};
