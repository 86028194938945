import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider, CssBaseline, Container } from '@mui/material';

import { FirebaseProvider } from './contexts/FirebaseContext';
import themes from './themes';
import { customization } from './Constants';
import Routes from './routes';

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <FirebaseProvider>
                    <Container>
                        <Routes />
                    </Container>
                </FirebaseProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
