import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from '../layouts';
import Loadable from '../ui-components/Loadable';
import AuthGuard from './route-guard/AuthGuard';

const CVWrapper = Loadable(lazy(() => import('../views')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/cv',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/cv/:tab',
            element: <CVWrapper />
        },
        {
            path: '/cv',
            element: <Navigate to="/cv/profile" />
        }
    ]
};

export default MainRoutes;
