import { lazy } from 'react';

// project imports
import GuestGuard from './route-guard/GuestGuard';
import MinimalLayout from '../layouts/MinimalLayout';
import Loadable from '../ui-components/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/auth/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/cv',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/cv/login',
            element: <AuthLogin />
        }
    ]
};

export default LoginRoutes;
