import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

// styles
const Main = styled("main")(({ theme }) => ({
  ...theme.typography.mainContent,
  ...{
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: "20px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      padding: "16px",
      marginRight: "10px",
    },
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Main theme={theme}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
