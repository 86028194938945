import axios from 'axios';

const baseURL = 'https://cyber-jam.com/api';

const axiosServices = axios.create({
    baseURL
});

const addBearerToken = (token) => {
    return {
        headers: {
            authorization: `Bearer ${token}`
        }
    };
};

export const fetchCV = (token, id) => axiosServices.get(`/cv/${id}`, addBearerToken(token));
export const sendEmail = (token, message) => axiosServices.post(`/cv/message`, message, addBearerToken(token));
