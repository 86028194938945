import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./assets/scss/style.scss";
import config from "./config";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
